import firebase from 'firebase/app';
import _, {
  size,
  get,
  pick,
  find,
  omit,
  map,
  includes,
  toNumber,
  sumBy,
  isNumber,
  has,
  merge,
} from 'lodash';
import 'firebase/firestore';
import 'firebase/storage';
import { configlab } from 'utils/configlab';
import nanoId from 'utils/nanoId';
const firestore = firebase.firestore();
const storage = firebase.storage();

export const calculateSummary = ({ values, builder }) => {
  const showCodes = get(values, 'summary.showCodes', true);

  const getData = () => {
    switch (get(values, 'summary.typeSummary')) {
      case 'custom':
        return get(values, 'summary.customSummary', []);
      case 'auto':
        return get(values, 'menu', []);
      case 'no-summary':
        return [];
      default:
        return [];
    }
  };
  const data = getData();

  const result = [];

  const calculateRow = (dataRow) => {
    const price = toNumber(
      // eslint-disable-next-line
      window.eval(`(()=>{
        try {
          const item = summary("${dataRow.id}");
          let value = item?.data("value");
          ${dataRow.priceAction}
        } catch (e) {
          console.error(e);
          return 0;
        }
      })()`) || 0
    );

    let getValue = dataRow.value;

    if (dataRow.type === 'row_summary') {
      getValue = window.eval(`(()=>{
        try {
          const item = summary("${dataRow.id}");
          let value = item?.data("value");
          ${dataRow.valueAction}
        } catch (e) {
          console.error(e);
          return 0;
        }
      })()`);
    }

    if (dataRow.type === 'checkbox' || dataRow.type === 'switch') {
      getValue = dataRow.value ? 'Yes' : 'No';
    }

    if (
      dataRow.type === 'radio_picker' ||
      dataRow.type === 'select' ||
      dataRow.type === 'color_picker'
    ) {
      getValue = get(
        _.find(dataRow.options, { value: dataRow.value }),
        'label'
      );
    }

    return {
      id: dataRow.id,
      value: getValue || '-',
      code: showCodes ? dataRow.code || '-' : false,
      label: dataRow.label || '-',
      type: 'row',
      price: dataRow.showPrice ? price ?? 0 : 0,
      position: dataRow.position,
    };
  };

  const calculateTitle = (dataRow) => ({
    ...pick(dataRow, ['position', 'label', 'id']),
    type: 'title',
  });

  const mapChildren = (children, position) =>
    map(children, (item) => {
      if (!item.showInSummary) {
        return false;
      }

      if (
        includes(
          ['section', 'dropdown', 'step-by-step', 'title_summary'],
          item.type
        )
      ) {
        result.push(calculateTitle({ position, ...item }));
        mapChildren(item.children, position + 1);
      } else {
        result.push(calculateRow({ position, ...item }));
      }
    });

  mapChildren(data, 0);

  const subtotal = sumBy(result, (i) => (isNumber(i.price) ? i.price : 0));
  const taxes =
    get(builder, 'taxes', 'included') === 'included'
      ? 0
      : (subtotal * get(builder, 'percentageTax', 0)) / 100;
  const total = (subtotal || 0) + (taxes || 0);

  const taxLabel = () => {
    const taxesIncluded = get(builder, 'taxes', 'included') === 'included';
    switch (get(builder, 'currency', 'USD')) {
      case 'AUD':
        return taxesIncluded ? 'GST Included' : 'GST';
      case 'NZD':
        return taxesIncluded ? 'GST Included' : 'GST';
      case 'EUR':
        return taxesIncluded ? 'VAT Included' : 'VAT';
      case 'USD':
        return taxesIncluded ? 'Taxes Included' : 'Tax';
    }
  };

  return { subtotal, total, taxes, taxLabel: taxLabel(), items: result };
};

export const getApi = async ({
  values,
  setValues,
  savedData,
  versionName = null,
}) => {
  const version = find(get(values, 'version.options', []), {
    name: versionName,
  });

  window.canShowDimensionsInputs = () => {
    const getFloorPlanner = find(get(values, 'general.options'), {
      typeTechnology: 'floorPlanner',
    });

    return get(values, 'general.defaultValue') === get(getFloorPlanner, 'name');
  };

  window.hideDimensionsInputs = (hide) => {
    //if hide is not provided, check which viewer is now active
    if (hide == undefined) hide = !canShowDimensionsInputs();

    const dims = document.getElementsByClassName('dimensions');
    for (let index = 0; index < dims.length; index++) {
      const element = dims[index];
      element.style.display = hide ? 'none' : '';
    }
  };

  window.savedData = savedData;
  window.values = () => values;
  window.setModel = (model) => {
    configlab({ setValues, values }).setModel(model);
    window.hideDimensionsInputs();
  };

  window.menu = (find) =>
    configlab({ setValues, values, section: 'menu', find }).get(find);

  window.convertToDate = (date) => {
    return new Date(
      has(date, 'seconds') ? date.seconds * 1000 : date
    ).toDateString();
  };

  window.scene = (find) =>
    configlab({
      setValues,
      values,
      section: 'scene',
      find,
    }).get(find);

  window.summary = (find) => {
    return configlab({
      setValues,
      values,
      section: 'summary.customSummary',
      find,
    }).get(find);
  };

  window.utils = () => {
    return {
      changeAdminEmail: (email) => {
        if (!email) return;

        values.adminEmail.email = email;
        setValues(values);
      },
      changeUserEmail: (email) => {
        if (!email) return;

        values.userEmail.email = email;
        setValues(values);
      },
      changeConfirmationEmail: (email) => {
        if (!email) return;

        values.confirmationEmail.email = email;
        setValues(values);
      },
      downloadSummary: async (role = 'user') => {
        const allFiles = Object.keys(values.files)
          .map((key) => ({
            ...values.files[key],
            id: key,
          }))
          .filter(
            ({ type, role: roleFile }) =>
              type === 'summary' && role === roleFile
          );

        // sort by name alphabetical
        const fileToDownload = allFiles
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          .reverse()?.[0];

        if (!fileToDownload) return null;

        const link = document.createElement('a');
        link.setAttribute('href', fileToDownload.url);
        link.setAttribute('target', '_blank');
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();

        return fileToDownload.url;
      },
    };
  };

  window.updateSummary = (toUpdate) => {
    values.summary = merge(values.summary, toUpdate);

    setValues(values);
  };

  window.form = (find) =>
    configlab({ setValues, values, section: 'form', find }).get(find);

  window.uploadFile = async (base64) => {
    const fileRef = storage.ref().child(`tempFiles/${nanoId(20)}`);

    await fileRef.putString(base64, 'data_url');
    return fileRef.getDownloadURL();
  };

  window.model = () => ({
    data: (toPick) =>
      configlab({
        data: {
          ...omit(get(values, 'general', {}), [
            'customOptions',
            'defaultValue',
            'options',
            'password',
          ]),
          floorPlanData: get(values, 'floorPlanData', {}),
          viewerName: get(values, 'general.defaultValue', ''),
          viewers: get(values, 'general.options', []),
          versions: get(values, 'version.options', []),
          versionName: versionName || get(values, 'version.defaultValue', ''),
          type: get(values, 'type', ''),
        },
      }).model(toPick),
  });

  const hasFloorPlanner = Boolean(
    find(get(values, 'general.options', []), {
      typeTechnology: 'floorPlanner',
    })
  );

  // TODO: detect is the floorplanner is loaded

  const runGlobalVars = async () => {
    window.noShowAlerts = true;

    try {
      await window.eval(
        `try{ ${get(values, 'general.globalVars')}} catch(e){console.error(e);}`
      );
    } catch (e) {
      console.error('Global vars error', e);
    }

    try {
      await window.eval(`(async ()=> { 
        ${version?.action ?? ''}
      })()`);
    } catch (e) {
      console.error(e);
    }

    window.noShowAlerts = false;
  };

  const checkIfModelsAreReady = setInterval(async () => {
    // if has floorplanner and floorplanner is loaded

    // window?.floorPlanner?.container
    if (hasFloorPlanner && window?.floorPlanner?.container) {
      await runGlobalVars();

      if (!window.floorPlanner.multiplePolylines || !window.floorPlanner.isBlockPolyline) {
        window.floorPlanner.sketchPoly =
          window.floorPlanner.createSketchPolyline({
            index: 0,
          });
      } else {
        window.blockPolyline(false);
      }

      clearInterval(checkIfModelsAreReady);

      const floorPlanData = window.model().data('floorPlanData') || {};

      const {
        floorPlanPosition = null,
        floorPlanPositionScale = null,
        inputsData = [],
        elementsLayer = [],
        sketchPoly = {},
        sketchPolys = [],
        shapediver = '',
        distanceBetweenPosts = {},
      } = floorPlanData;

      if (floorPlanPosition != null)
        window?.floorPlannerStage?.position(floorPlanPosition);
      if (floorPlanPositionScale != null)
        window?.floorPlannerStage?.scale(floorPlanPositionScale);

      const data = find(window.model().data('viewers'), {
        typeTechnology: 'floorPlanner',
      });

      const AsyncFunction = new Function(
        `return Object.getPrototypeOf(async function(){}).constructor`
      )();

      try {
        window.floorPlanner.changePolygonAction = new AsyncFunction(
          get(data, 'changePolygonAction', '')
        );
      } catch (_) {}

      const typeModel = get(values, 'type', '');
      window.typeModel = typeModel;

      if (hasFloorPlanner && typeModel === 'order') {
        for (let index = 0; index < sketchPolys.length; index++) {
          window?.floorPlanner?.createSketchPolyline({
            index,
            points: sketchPolys[index]?.points ?? [],
            closed: sketchPolys[index]?.closed ?? false,
          });
        }
        window.floorPlanner.editingSketchPoly = sketchPolys.length == 0;

        if (window?.floorPlanner?.showPillars) {
          window.floorPlanner.distanceBetweenPosts = distanceBetweenPosts;
        }

        window.floorPlanner.inputsData = inputsData;

        for (let index = 0; index < elementsLayer.length; index++) {
          window?.floorPlanner?.createElement(
            elementsLayer[index],
            elementsLayer[index]?.id ?? nanoId(10)
          );
        }

        window.floorPlanner.elementsLayer.batchDraw();

        const intervalElement = setInterval(() => {
          const array = window.floorPlanner.elementsLayer.children;

          if (array.length === elementsLayer.length || shapediver === '') {
            if (sketchPoly) {
              const executeFinishDrawing = setInterval(() => {
                if (window.floorPlanner.isInside) {
                  for (let index = 0; index < sketchPolys.length; index++) {
                    window.floorPlanner.sketchIndex = index;
                    window.floorPlanner.finishDrawing();
                  }

                  clearInterval(executeFinishDrawing);
                }
              }, 500);
            }

            const intervalToExecuteFunctions = setInterval(() => {
              // TODO: verify if the elements are loaded

              if (shapediver) {
                //window.sdApi.parameters.updateAsync(shapediver);
              }

              const executeActionByStep = setInterval(async () => {
                if (window.modelIsReady && window?.floorPlanner?.isInside) {
                  window.noShowAlerts = true;
                  await window.floorPlanner.changePolygonAction();
                  window.noShowAlerts = false;

                  setTimeout(() => {
                    window.noShowAlerts = true;

                    map(
                      map(get(values, 'menu', []), 'action').slice(
                        0,
                        getActiveStep() + 1
                      ),
                      (action) =>
                        window.eval(`(async ()=> { 
                        ${action}
                       })()`)
                    );

                    window.noShowAlerts = false;
                  }, 1000);

                  clearInterval(executeActionByStep);
                }
              }, 500);

              clearInterval(intervalToExecuteFunctions);
            }, 500);

            clearInterval(intervalElement);
          }
        }, 1000);
      }
      window.hideDimensionsInputs();
    }

    if (hasFloorPlanner && !window?.floorPlanner?.container) {
      return;
    }

    if (!hasFloorPlanner) {
      await runGlobalVars();
    }

    const getNameModel = model().data('name');
    const getDescriptionModel = model().data('introMessage');

    if (getNameModel && getDescriptionModel) {
      window.showAlert(getNameModel, getDescriptionModel);
    }

    clearInterval(checkIfModelsAreReady);
  }, 1000);
};

function jsonToCsv(data) {
  const headers = Object.keys(data[0]);
  const rows = data.map((row) => headers.map((header) => row[header]));
  return [headers, ...rows].map((row) => row.join(',')).join('\n');
}

export const downloadData = async ({
  collectToDownload,
  dataToShow = [],
  filter = [],
}) => {
  const collectionRef = firestore.collection(collectToDownload);

  let getItems;

  switch (size(filter)) {
    case 1:
      getItems = await collectionRef
        .where(
          get(filter, '[0][0]'),
          get(filter, '[0][1]'),
          get(filter, '[0][2]')
        )
        .get();
      break;
    case 2:
      getItems = await collectionRef
        .where(
          get(filter, '[0][0]'),
          get(filter, '[0][1]'),
          get(filter, '[0][2]')
        )
        .where(
          get(filter, '[1][0]'),
          get(filter, '[1][1]'),
          get(filter, '[1][2]')
        )
        .get();
      break;
    default:
      getItems = await collectionRef.get();
  }

  const items = getItems.docs.map((doc) => ({
    uid: doc.id,
    ...doc.data(),
  }));

  const csv = jsonToCsv(items.map((item) => pick(item, dataToShow)));

  //await jsonexport(items.map((item) => pick(item, dataToShow)));

  const element = document.createElement('a');
  const file = new Blob([csv], {
    type: 'text/csv',
  });
  element.href = URL.createObjectURL(file);
  element.download = `${collectToDownload}-${new Date().getTime()}.csv`;
  document.body.appendChild(element);
  element.click();
  element.remove();
};
